import { render, staticRenderFns } from "./ClientChat.vue?vue&type=template&id=84f4eba6&scoped=true&"
import script from "./ClientChat.vue?vue&type=script&lang=js&"
export * from "./ClientChat.vue?vue&type=script&lang=js&"
import style1 from "./ClientChat.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84f4eba6",
  null
  
)

export default component.exports