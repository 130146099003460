<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <!-- Sidebar Content -->
      <div
        class="sidebar-content"
        :class="{ 'show': mqShallShowLeftSidebar }"
      >

        <!-- Header -->
        <div class="chat-fixed-search d-flex justify-content-between px-1">
          <!-- Sidebar close icon -->
          <b-button
            size="sm"
            variant="flat-danger"
            class="btn-icon ml-n50 mr-25 d-md-none"
          >
            <feather-icon
              icon="XIcon"
              @click="$emit('close-mq-shall-show-left-sidebar')"
            />
          </b-button>

          <div class="d-flex align-items-center w-100">
            <b-input-group class="input-group-merge round w-100">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input
                v-model="search"
                :placeholder="$t('Search')"
                class="input-search"
              />
            </b-input-group>
          </div>
        </div>

        <b-button v-if="checkRequiredModule('whatsappModule')"
                  variant="primary"
                  size="sm"
                  class="w-100"
                  @click="$emit('start-conversation')"
        >
          <feather-icon icon="PlusIcon"
                        class="mr-25"
          />
          {{ $t('StartWhatsappConversation') }}
        </b-button>

        <!-- ScrollArea: Chat & Contacts -->
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area mt-0 pt-0"
          @ps-y-reach-end="load"
        >
          <h4 class="chat-list-title">
            {{ $t('ActiveChats') }}
          </h4>
          <!-- Chats -->
          <ul
            v-if="getFilteredAvailableUsers.length"
            class="chat-users-list chat-list media-list"
          >
            <!-- eslint-disable -->
            <chat-client
                v-for="conversation in getFilteredAvailableUsers"
                :key="`user_${conversation.id}`"
                :conversation.async="conversation"
                is-chat-contact
                tag="li"
                :is-active="selectedConversationId === conversation.id"
                :class="{ 'active': selectedConversationId === conversation.id }"
                @update-conversation-field="$emit('update-conversation-field', $event)"
                @click="
                  if ($event && ['svg', 'polygon', 'rect'].includes($event.target.tagName.toLowerCase())) return

                  openConversation(conversation.id, conversation)
                  $emit('close-mq-shall-show-left-sidebar')
              "
            />
          </ul>
          <div
              v-else
              class="pl-2"
          >
            <feather-icon icon="MinusIcon" />
          </div>
        </vue-perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ChatClient from '@/views/core/chat/ChatClient.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ChatClientsSidebar',
  components: {
    // 3rd party
    VuePerfectScrollbar,
    // SFC
    ChatClient,
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser',
    }),
    getAvailableUsers() {
      const items = this.availableUsers
      return items.sort((a, b) => {
        if (a.clientChatMessageLastAt && b.clientChatMessageLastAt) {
          const dateA = new Date(a.clientChatMessageLastAt.date)
          const dateB = new Date(b.clientChatMessageLastAt.date)
          return dateB.getTime() - dateA.getTime()
        } if (a.clientChatMessageLastAt) {
          return -1
        } if (b.clientChatMessageLastAt) {
          return 1
        }
        return 0
      })
    },
    getFilteredAvailableUsers() {
      const s = this.search.toLowerCase()
      return this.getAvailableUsers.filter(user => (
          user?.chatClient?.firstName?.toLowerCase()?.includes(s)
          || user?.chatClient?.lastName?.toLowerCase()?.includes(s)
          || user?.chatPhoneNumber?.phone?.toLowerCase()?.includes(s)
          || (user.contactThreads?.length && (user.contactThreads[0]?.name?.toLowerCase()?.includes(s) || user.contactThreads[0]?.contact?.firstName?.toLowerCase()?.includes(s) || user.contactThreads[0]?.contact?.lastName?.toLowerCase()?.includes(s)))
          || user.source === 'WIDGET'
      )) || []
    },
  },
  props: {
    selectedConversationId: {
      type: String,
      default: '',
      required: false,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      default: false,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
      required: true,
    },
    availableUsers: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      required: true,
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }
    return {
      perfectScrollbarSettings,
    }
  },
  data: () => ({
    search: '',
  }),
  methods: {
    load() {
      if (this.loading) {
        return false
      }
      this.$emit('load-new-messages')
      return true
    },
    openConversation(conversationId, conversation) {
      // eslint-disable-next-line
      conversation.chatConversationMessagesUnreadAmount = 0
      this.$emit('open-conversation', conversationId, conversation)
    },
  },
}
</script>

<style lang="scss">
.chat-application .sidebar-content .chat-user-list-wrapper li {
  padding: 4px 0 !important;

  .message-box {
    padding: 0.786rem 0 0.786rem 1.286rem !important;
  }
}

.chat-application .sidebar-content .chat-user-list-wrapper ul {
  margin-bottom: 4rem !important;
}
</style>
